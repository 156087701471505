<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Search Leads
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="32">
            <a-col :md="5">
              <a-form-item name="criteria">
                <a-select
                v-model:value="formState.criteria"
                style="width: 100%"
                @change="onChange"
                showSearch
                >
                <a-select-option value="name">Name</a-select-option>
                <a-select-option value="company">Company</a-select-option>
                <a-select-option value="mobile">Mobile</a-select-option>
                <a-select-option value="website">Website</a-select-option>
                <a-select-option value="email">E-mail</a-select-option>
                <a-select-option value="industry">Industry</a-select-option>
                <a-select-option value="current_status">Current Lead Status</a-select-option>
                <a-select-option value="board_numbers">Leads With Direct Mobile Numbers</a-select-option>
                <a-select-option value="all_leads">All Leads Without Existing Clients</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispTeam">
            <a-form-item name="team">
            <a-select
                v-model:value="formState.team"
                style="width: 100%"
                @change="onChange1"
                showSearch
                >
                <a-select-option value="Accounting">Accounting</a-select-option>
                <a-select-option value="SME">SME</a-select-option>
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispTerm">
            <a-form-item name="condition">
            <a-select
                v-model:value="formState.condition"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="equals">Equals</a-select-option>
                <a-select-option value="contains">Contains</a-select-option>
                <a-select-option value="begin with">Begins with</a-select-option>
                <a-select-option value="does not contain">Does not Contain</a-select-option>
                
            </a-select>    
            </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispTerm">
              <a-form-item name="term">
               <a-input
                  v-model:value="formState.term"
                  placeholder="Input Search Term"
                />

              </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispIndustry">
            <a-form-item name="industry">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col> 
            <a-col :md="5" v-if="dispStatus">
              <a-form-item name="status">
                <a-select
                v-model:value="formState.status"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="Target Assignment">Fresh Target</a-select-option>
                <a-select-option value="Opportunity Assignment">Fresh Opportunity</a-select-option>
                <a-select-option value="Target In-Progress">Assigned Target</a-select-option>
                <a-select-option value="Mail Sent">Mail Sent</a-select-option>
                <a-select-option value="Opportunity In-Progress">Assigned Opportunity</a-select-option>
                <a-select-option value="Dead">Dead Lead</a-select-option>
                <a-select-option value="Lead In-Progress"> Lead In-Progress</a-select-option>
                <a-select-option value="Proposal">Proposal Review</a-select-option>
                <a-select-option value="Negotiation Review">Negotiation Review</a-select-option>
                <a-select-option value="Closed Lost"> Closed Lost</a-select-option>
                <a-select-option value="Closed Won">Closed Won</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="3">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="search" size="14" />  &nbsp; 
              Search Leads</span>
              </a-button>
             </a-form-item>
              </a-col>
             
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>
      <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15" style="padding: 10px">
       <a-col :xs="24">
          <TopToolBox>
          <div class="product-single-description">
          <div class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item name="search_text">
                            <a-button class="btn-signin" size="small" @click="exportToCSV()">
                          <span
                          style="
                          display: inline-flex !important;
                          align-items: center !important;
                          "
                          >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
  <a-modal
  :type="primary"
  title="Lead Alert"
  :visible="localState.schedVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Dead/Lost</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal
  :type="primary"
  title="Existing Customer Alert"
  :visible="localState.wonVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Already Won</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal
  :type="primary"
  title="Unsubscribed Customer Alert"
  :visible="localState.unsubVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Unsubscribed</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'SearchForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var userData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var dispIndustry = ref(false);
    var dispStatus = ref(false);
    var dispTerm = ref(true);
    var dispTeam = ref(false);
    var selectedoption = ref('Active');
    const selectedChange = ref(null);
    const selectedChange1 = ref(null);
    var industryData = ref();
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.SearchData.data = [];
    onMounted(() => {
    loadData();

    });
const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id +'/0',
          "_self",
      ); 
    }  
    const localState = reactive({
      schedVisible: false,
      wonVisible: false,
      unsubVisible:false,
    });
    const handleCancel = () => {
      localState.schedVisible= false;
      localState.wonVisible= false;
      localState.unsubVisible= false;
    };
    const showModal = () => {
      localState.schedVisible= true;
    };
    const showModal1 = () => {
      localState.wonVisible= true;
    };
    const showModal2 = () => {
      localState.unsubVisible= true;
    };
    const formState = reactive({
      criteria: "Select Criteria",
      condition: "Select Condition",
      term: "",
      team: "Select Team",
      industry: "Select Industry",
      status: "Select Current Lead Status",
    });
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    var LeadsData1 = [];
    const handleSearch = (values) => {
      const substring = addFormState.search_text.toLowerCase();
  //const leadsData = JSON.parse(localStorage.getItem("search_data1"));
  const leadsData =LeadsData1.data;
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring)
  );
  
  // Update the state with filtered data
  state.SearchData.data = filteredData;
    };
    const onChange=(change)=> {
     selectedChange.value=change;
     if(selectedChange.value=="industry"){
     dispIndustry.value=true;
     dispTerm.value=false;
     dispStatus.value=false;
     dispTeam.value=false;
     }
     else if(selectedChange.value=="board_numbers"){
     dispIndustry.value=false;
     dispStatus.value=false;
     dispTerm.value=false;
     dispTeam.value=true;
     }
     else if(selectedChange.value=="all_leads"){
     dispIndustry.value=false;
     dispStatus.value=false;
     dispTerm.value=false;
     dispTeam.value=false;
     }
     else if(selectedChange.value=="current_status"){
     dispIndustry.value=false;
     dispStatus.value=true;
     dispTerm.value=false;
     dispTeam.value=false;
     }
     else{
      dispIndustry.value=false;
      dispStatus.value=false;
      dispTerm.value=true;
      dispTeam.value=false;
     }
    }
    const onChange1=(change)=> {
     selectedChange1.value=change;
     if(selectedChange1.value=="SME"){
     dispIndustry.value=true;
     dispTerm.value=false;
     dispStatus.value=false;
     dispTeam.value=true;
     }
     else{
      dispIndustry.value=false;
      dispStatus.value=false;
      dispTerm.value=false;
      dispTeam.value=true;
     }
    }
    const loadData = () => {
        loading.value = true;
        dispatch("manualFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    industryData.value = response.data[0];
                    
                    
                } else {
                loading.value = false;
                       }
            },
            (error) => {
                loading.value = false;
            } 
          
        );
    }
    const rules = {
      criteria: [
        {
          required: true,
          message: "Please select criteria first",
          trigger: "blur",
        },
      ],
      condition: [
        {
          required: true,
          message: "Please select condition first",
          trigger: "blur",
        },
      ],
      
      term: [
        {
          required: false,
          message: "Please select to term first!",
          trigger: "blur",
        },
      ],
      };
    const handleOk = (values) => {
      searchData();
    };
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
  
    const searchData = () => {
        let api_data = [];
        api_data['criteria'] = formState.criteria;
        api_data['condition'] = formState.condition;
        api_data['term'] = formState.term;
        api_data['team'] = formState.team;
        api_data['industry'] = formState.industry;
        api_data['status'] = formState.status;
        loading.value = true;
        dispatch("searchLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.SearchData.data = response.data;
                    LeadsData1.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.SearchData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const TableColumns = [
      {
        title: "Company Name",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Data Source",
        dataIndex: "Lead_Source",
        key: "Lead_Source",
        sorter: (a, b) => a.Lead_Source.length - b.Lead_Source.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Center",
        dataIndex: "branch_code",
        key: "branch_code",
        sorter: (a, b) => a.branch_code.length - b.branch_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Industry",
        dataIndex: "Industry",
        key: "Industry",
        sorter: (a, b) => a.Industry.length - b.Industry.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Interest",
        dataIndex: "interest",
        key: "interest",
        sorter: (a, b) => a.interest.length - b.interest.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Board Numbers",
        dataIndex: "board_nos",
        key: "board_nos",
        sorter: (a, b) => a.board_nos.length - b.board_nos.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Mobile",
        dataIndex: "Mobile",
        key: "Mobile",
        sorter: (a, b) => a.Mobile.length - b.Mobile.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Website",
        dataIndex: "Website",
        key: "Website",
        sorter: (a, b) => a.Website.length - b.Website.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email Unsubscribed",
        dataIndex: "email_unsubscribe",
        key: "email_unsubscribe",
        sorter: (a, b) => a.email_unsubscribe.length - b.email_unsubscribe.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
     let role = JSON.parse(localStorage.getItem("usr_role"));
     let TableData = computed(() =>
        state.SearchData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            Phone,
            Mobile,
            Email_Address,
            Website,
            branch_code,
            current_status,
            email_unsubscribe,
            board_nos,
            Industry,
            interest,
            Lead_Source,
            } = unreconcile;
            let companyComponent;
    if (
  current_status !== "Dead" &&
  current_status !== "Closed Lost" &&
  current_status !== "Closed Won" &&
  email_unsubscribe == "false" &&
  (role.role_id !== "1" && role.role_id !== "2")
) {
  companyComponent = (
    <div class="table-actions">
      <a
        style="text-align: left !important;"
        onClick={() => openurl(id)}
        href="#"
      >
        <span>{Company}</span>
      </a>
    </div>
  );
} else if (
  (current_status == "Dead" ||
    current_status == "Closed Lost" ||
    current_status == "Closed Won") &&
  email_unsubscribe == "false" &&
  (role.role_id == "1" || role.role_id == "2")
) {
  companyComponent = (
    <div class="table-actions">
      <a
        style="text-align: left !important;"
        onClick={() => openurl(id)}
        href="#"
      >
        <span>{Company}</span>
      </a>
    </div>
  );
} else if (
  current_status === "Closed Won" &&
  email_unsubscribe == "false" &&
  (role.role_id !== "1" && role.role_id !== "2")
) {
  companyComponent = (
    <div class="table-actions">
      <a
        style="text-align: left !important;"
        onClick={() => showModal1()}
        href="#"
      >
        <span>{Company}</span>
      </a>
    </div>
  );
} else if (
  (current_status === "Closed Lost" || current_status === "Dead") &&
  (role.role_id !== "1" && role.role_id !== "2")
) {
  companyComponent = (
    <div class="table-actions">
      <a
        style="text-align: left !important;"
        onClick={() => showModal()}
        href="#"
      >
        <span>{Company}</span>
      </a>
    </div>
  );
} else {
  companyComponent = (
    <div class="table-actions">
      <a
        style="text-align: left !important;"
        onClick={() => showModal2()}
        href="#"
      >
        <span>{Company}</span>
      </a>
    </div>
  );
}
          return {
            key: id,
            id: id,
            Company:  companyComponent,
            Name:Name,
            Phone: Phone,
            Mobile: Mobile,
            Email_Address: Email_Address,
            Website: Website,
            branch_code: branch_code,
            current_status: current_status,
            email_unsubscribe: email_unsubscribe,
            board_nos: board_nos,
            Industry: Industry,
            interest: interest,
            Lead_Source: Lead_Source,
            } 
            
        }
        )
    );
      const exportToCSV = () => {
      const inputData = state.SearchData.data.map((unreconcile) => {
        const {
            id,
            Company,
            Name,
            Phone,
            Mobile,
            Email_Address,
            Website,
            branch_code,
            current_status,
            email_unsubscribe,
            board_nos,
            Industry,
            interest,
            Lead_Source,
        } = unreconcile;
        return {
            key: id,
            id: id,
            Company:  Company,
            Name: Name,
            "Data Source": Lead_Source,
            "Branch Code": branch_code,
            Industry: Industry,
            Interest: interest,
            "Board Numbers": board_nos,
            Phone: Phone,
            Mobile: Mobile,
            "Email Address": Email_Address,
            Website: Website,
            "Current Status": current_status,
            "Email Unsubscribe": email_unsubscribe,
            
          };
      });

      var linedata = [];

      
      
      const fileName ="Lead Data Search Export";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(inputData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };  
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      selectedChange,
      selectedChange1,
      userData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      openurl,
      formState,
      rules,
      loadData,
      industryData,
      onChange,
      onChange1,
      dispIndustry,
      dispTerm,
      dispTeam,
      dispStatus,
      handleCancel,
      localState,
      showModal,
      showModal1,
      showModal2,
      addFormState,
      handleSearch,
      LeadsData1,
      exportToCSV,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>